
export default{
    show(){

        return [
            {
                auth: ['Administrador', 'Técnico', 'Solicitante'],
                icon: 'fa fa-tachometer-alt',
                label: 'Dashboard',
                route: '/home',
            },
            {
                auth: ['Administrador', 'Técnico', 'Solicitante'],
                icon: 'fa fa-calendar-alt',
                label: 'Ano',
                route: '/year',
            },
            {
                auth: ['Administrador', 'Técnico', 'Solicitante'],
                icon: 'fas fa-globe',
                label: 'Acesso',
                route: '/access',
            },
            {
                auth: ['Administrador', 'Técnico'],
                icon: 'fas fa-user',
                label: 'Usuário',
                route: '/user',
            },
            {
                auth: ['Administrador'],
                icon: 'fas fa-phone',
                label: 'Atendimento',
                route: '/callcenter',
            },
            {
                auth: ['Administrador', 'Técnico'],
                icon: 'fas fa-building',
                label: 'Setor',
                route: '/sector',
            },
            {
                auth: ['Administrador', 'Técnico'],
                icon: 'fas fa-desktop',
                label: 'SubSetor',
                route: '/subsector',
            },
            {
                auth: ['Solicitante'],
                icon: 'fas fa-envelope',
                label: 'Solicitação',
                route: '/request',
            },
            {
                auth: ['Administrador', 'Técnico'],
                icon: 'fas fa-undo-alt',
                label: 'Resposta',
                route: '/response',
            },
        ]
    }
}