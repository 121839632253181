import LoginComponent from './../components/login/LoginComponent'
import PasswordRecoveryComponent from './../components/login/PasswordRecoveryComponent'
import HomeComponent from './../components/home/HomeComponent'
import UserComponent from './../components/user/UserComponent'
import UserCreateComponent from './../components/user/UserCreateComponent'
import UserLevelComponent from './../components/user-level/UserLevelComponent'
import CallCenterComponent from './../components/callcenter/CallCenterComponent'
import CallCenterCreateComponent from './../components/callcenter/CallCenterCreateComponent'
import CallCenterUserComponent from './../components/callcenter-user/CallCenterUserComponent'
import CallCenterUserCreateComponent from './../components/callcenter-user/CallCenterUserCreateComponent'
import YearComponent from './../components/year/YearComponent'
import YearCreateComponent from './../components/year/YearCreateComponent'
import AccessComponent from './../components/access/AccessComponent'
import SectorComponent from './../components/sector/SectorComponent'
import SectorCreateComponent from './../components/sector/SectorCreateComponent'
import SubSectorComponent from './../components/subsector/SubSectorComponent'
import SubSectorCreateComponent from './../components/subsector/SubSectorCreateComponent'
import SubSectorUserComponent from './../components/subsector-user/SubSectorUserComponent'
import SubSectorUserCreateComponent from './../components/subsector-user/SubSectorUserCreateComponent'
import RequestComponent from './../components/request/RequestComponent'
import RequestCreateComponent from './../components/request/RequestCreateComponent'
import RequestConcludedComponent from './../components/request/RequestConcludedComponent'
import ResponseComponent from './../components/response/ResponseComponent'
import ResponseCreateComponent from './../components/response/ResponseCreateComponent'
import ReportOrderServiceComponent from './../components/reports/ReportOrderServiceComponent'

import Guard from './../middleware/authentication'
import Manager from '../middleware/manager'
import Tech from '../middleware/tech'

export default[
    //LOGIN
    {path: '/', component: LoginComponent},
    {path: '/password/recovery', component: PasswordRecoveryComponent},
    //HOME
    {path: '/home', component: HomeComponent, beforeEnter: Guard.auth},
    //YEAR
    {path: '/year', component: YearComponent, beforeEnter: Guard.auth},
    {path: '/year/create', component: YearCreateComponent, beforeEnter: Guard.auth},
    //ACCESS
    {path: '/access', component: AccessComponent, beforeEnter: Guard.auth},
    //USER
    {path: '/user', component: UserComponent, beforeEnter: Tech.auth},
    {path: '/user/create', component: UserCreateComponent},
    //USER-LEVEL
    {path: '/user/:user/level', component: UserLevelComponent, beforeEnter: Tech.auth},
    //CALL-CENTER
    {path: '/callcenter', component: CallCenterComponent, beforeEnter: Manager.auth},
    {path: '/callcenter/create', component: CallCenterCreateComponent, beforeEnter: Manager.auth},
    {path: '/callcenter/:id/edit', component: CallCenterCreateComponent, beforeEnter: Manager.auth},
    //CALL-CENTER-USER
    {path: '/callcenter/:id/user', component: CallCenterUserComponent, beforeEnter: Manager.auth},
    {path: '/callcenter/create/:id/user', component: CallCenterUserCreateComponent, beforeEnter: Manager.auth},
    //SECTOR
    {path: '/sector', component: SectorComponent, beforeEnter: Tech.auth},
    {path: '/sector/create', component: SectorCreateComponent, beforeEnter: Tech.auth},
    {path: '/sector/:id/edit', component: SectorCreateComponent, beforeEnter: Tech.auth},
    //SUB-SECTOR
    {path: '/subsector', component: SubSectorComponent, beforeEnter: Tech.auth},
    {path: '/subsector/create', component: SubSectorCreateComponent, beforeEnter: Tech.auth},
    {path: '/subsector/:id/edit', component: SubSectorCreateComponent, beforeEnter: Tech.auth},
    //SUB-SECTOR-USER
    {path: '/subsector/:id/user', component: SubSectorUserComponent, beforeEnter: Tech.auth},
    {path: '/subsector/create/:id/user', component: SubSectorUserCreateComponent, beforeEnter: Tech.auth},
    //REQUEST
    {path: '/request', component: RequestComponent, beforeEnter: Guard.auth},
    {path: '/request/create', component: RequestCreateComponent, beforeEnter: Guard.auth},
    {path: '/request/:id/edit', component: RequestCreateComponent, beforeEnter: Guard.auth},
    {path: '/request/:id/concluded', component: RequestConcludedComponent, beforeEnter: Guard.auth},
    //RESPONSE
    {path: '/response', component: ResponseComponent, beforeEnter: Guard.auth},
    {path: '/response/:request/create', component: ResponseCreateComponent, beforeEnter: Guard.auth},
    {path: '/response/:id/edit', component: ResponseCreateComponent, beforeEnter: Guard.auth},
    //REPORTS
    {path: '/report/:id/atendimento', component: ReportOrderServiceComponent, beforeEnter: Guard.auth},
]