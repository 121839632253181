import OSCookie from 'js-cookie'

export default{

    auth(to, from, next){

        const useraccess = OSCookie.get('_useraccess')

        if(useraccess == 'administrador' || useraccess == 'técnico')
        {
            next()
        }
        else 
        {
            next('/')
        }
    }
}