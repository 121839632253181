import { render, staticRenderFns } from "./LoadingComponent.vue?vue&type=template&id=307a2aa8&scoped=true&"
var script = {}
import style0 from "./LoadingComponent.vue?vue&type=style&index=0&id=307a2aa8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "307a2aa8",
  null
  
)

export default component.exports