<template>
    <div>
        <confirm-component v-if="confirmAccess" :id="userAccess.call_center_name ? userAccess.call_center_name : userAccess.sub_sector_name" message="DESEJA REALMENTE ALTEAR O TIPO DE ACESSO PARA " @confirm="access()" @cancel="cancel()" />
        
        <message-component v-if="message" :message="message"/>

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Acesso</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12">
                        <h4 class="mt-1">{{ title }}</h4>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <!-- TABELA DE LOCAIS -->
                <table class="table table-hover">
                    <thead class="size">
                        <tr>
                        <th>#</th>
                        <th>LOCAL</th>
                        <th class="text-center">AÇÕES</th>
                        </tr>
                    </thead>

                    <tbody class="size">
                        <tr v-for="(locale, index) in locales.data" :key="index">
                            <td class="pt-3">{{ (locale.call_center_id ? locale.call_center_id : locale.sub_sector_id) }}</td>
                            <td class="pt-3">{{ (locale.call_center_name ? locale.call_center_name : locale.sub_sector_name) }}</td>
                            <td class="text-center">
                                <a href="" class="btn btn-success mr-1" @click.prevent="confirm('SENT', locale)" title="Acessar"><i class="fas fa-sign-in-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="locales.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(locales.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="locales.current_page + 1 <= locales.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(years.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p class="text-center">{{ 'Página ' + locales.current_page + ' / ' + locales.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>
import OSCookie from 'js-cookie'
import axios from 'axios'
import MessageComponent from './../snippets/MessageComponent'
import LoadingComponent from './../snippets/LoadingComponent'
import ConfirmComponent from './../snippets/ConfirmComponent.vue'

export default {
    components:{

        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{
            title: 'Locais',
            message: false,
            locales:{
                data: {},
                current_page: 1,
                last_page: 1,
            },
            preloader: false,
            error: false,
            userAccess: {},
            confirmAccess: false,
        }
    },
    created(){

        this.getLocales()
    },
    methods:{

        getLocales()
        {
            this.preloader = true

            let type = OSCookie.get('_useraccess')
            let user = OSCookie.get('_userid')

            axios.post(this.url + '/users/locales', {type: type, user: user}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.locales.data = response.data.data
                this.locales.last_page = response.data.last_page

            }, error => {

                this.error = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        pagination(pageNumber){

            this.locales.current_page = pageNumber
            this.getLocales()
        },
        confirm(type, param){

            this.confirmAccess = true

            if(type == 'SENT')
            {
                this.userAccess = param
            }
        },
        access(){

            let id = (this.userAccess.call_center_id ? this.userAccess.call_center_id : this.userAccess.sub_sector_id)
            let name = (this.userAccess.call_center_name ? this.userAccess.call_center_name : this.userAccess.sub_sector_name)

            OSCookie.set('_locale_id', id)
            OSCookie.set('_locale_name', name)
            this.$router.go(0)
        },
        cancel()
        {
            this.userAccess = {}
            this.confirmAccess = false
        }
    },
}
</script>

<style scoped>
    .size 
    {
        font-size: 10pt;
    }
</style>