<template>
    <div>
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/subsector">SubSetores</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Criar</a>
        </div>

        <div class="card">

            <div class="card-header">
                <h4 class="text-center mt-1" v-if="!id">{{ titleCreate }}</h4>
                <h4 class="text-center mt-1" v-if="id">{{ titleEdit }}</h4>
            </div>

            <div class="card-body">
                <form class="app_form" @submit.prevent="saveSubSector">

                    <input type="hidden" name="sector_id" v-model="subsector.sector_id">

                    <div class="col-12">
                        <div class="row">

                            <select-component
                                :options="sectors" 
                                label="Setor"
                                nameInput="sector_name"
                                :valueInput="subsector.sector_name"
                                :message="message"
                                v-model="subsector.sector_name"
                                @vmodel="getModel"
                            />

                            <div class="form-group col-12">
                                <span style="color: #999; font-weight: bold;">Nome</span>
                                <input class="form-control" type="text" v-model="subsector.name">
                                <small class="error" v-if="message.type == 'error'" v-text="message.message.name"></small>
                            </div>
                            <!-- {{-- LINHA 02 --}} -->
                            <div class="form-group col-12">
                                <span style="color: #999; font-weight: bold;">Descrição</span>
                                <input class="form-control" type="text" v-model="subsector.description">
                                <small class="error" v-if="message.type == 'error'" v-text="message.message.description"></small>
                            </div>
                            <!-- {{-- LINHA 03 --}} -->
                            <div class="form-group col-12">
                                <span style="color: #999; font-weight: bold;">Endereço</span>
                                <input class="form-control" type="text" v-model="subsector.address">
                                <small class="error" v-if="message.type == 'error'" v-text="message.message.address"></small>
                            </div>
                            <!-- {{-- LINHA 04 --}} -->
                            <div class="form-group col-12 col-lg-8">
                                <span style="color: #999; font-weight: bold;">Email</span>
                                <input class="form-control" type="text" v-model="subsector.email">
                                <small class="error" v-if="message.type == 'error'" v-text="message.message.email"></small>
                            </div>
                            <div class="form-group col-12 col-lg-4">
                                <span style="color: #999; font-weight: bold;">Celular</span>
                                <input class="form-control" type="text" v-model="subsector.phone">
                                <small class="error" v-if="message.type == 'error'" v-text="message.message.phone"></small>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success mt-2 align-right">Salvar Alterações</button>
                    </div>
                </form>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import OSCookie from 'js-cookie'
import LoadingComponent from '../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
// import InputTextAutoCompleteComponent from './../snippets/InputTextAutoCompleteComponent.vue'
import SelectComponent from './../snippets/SelectComponent.vue'
import axios from 'axios'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        // InputTextAutoCompleteComponent,
        SelectComponent,
    },
    props: [
        'url',
        'token'
    ],
    data() {
    return{

      titleCreate: 'Cadastro de SubSetor',
      titleEdit: 'Edição de SubSetor',
      subsector:{
          call_center_id: OSCookie.get('_locale_id'),
          sector_id: '',
          sector_name: '',
          name: '',
          description: '',
          address: '',
          email: '',
          phone: '',
      },
      message: {},
      preloader: false,
      id: this.$route.params.id,
      sectors: {},
      valueEdit: '',
      localeId: OSCookie.get('_locale_id'),
    }
  },
  created(){

    this.getSectors()

    if(this.id){

        this.getSubSector()
    }
  },
  methods:{
      getModel(sector){
          
          this.subsector.sector_id = sector.id
          this.subsector.sector_name = sector.name
      },
    getSubSector(){

        this.preloader = true

        axios.post(this.url + '/subsectors/edit', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

            this.subsector = response.data

        }, error => {

            this.subsector = error.response

        }).finally(() => {

            this.preloader = false
        })

    },
    getSectors(){

        axios.post(this.url + '/subsectors/sectors', {locale: this.localeId, substr: this.subsector.sector_name}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

            this.sectors = response.data
        })
    },
    saveSubSector(){

        if(!this.id)
        {
            this.createSubSector()
        }
        else
        {
            this.editSubSector()
        }
    },
    createSubSector(){

        this.preloader = true
        
        console.log(this.subsector)
            
        axios.post(this.url + '/subsectors/store', this.subsector, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

            this.message = response.data

            if(this.message.type != 'error')
            {
                this.$router.push('/subsector')
            }

        }, error => {

            this.message = error.response

        }).finally(() => {

            this.preloader = false
        })

    },editSubSector(){

        this.preloader = true

        let data = {id: this.id, subsector: this.subsector}
        
        axios.put(this.url + '/subsectors/update', data, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

            this.message = response.data

            if(this.message.type != 'error' && this.message.type != 'warning')
            {
                this.$router.push('/subsector')
            }

        }, error => {

            this.message = error.response

        }).finally(() => {

            this.preloader = false
        })
    },
  },
}
</script>

<style scoped>
    ul 
    {
        list-style: none;
    }
    .error 
    {
        color: red;
    }
</style>