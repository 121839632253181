import { render, staticRenderFns } from "./ResponseComponent.vue?vue&type=template&id=32ac3d71&scoped=true&"
import script from "./ResponseComponent.vue?vue&type=script&lang=js&"
export * from "./ResponseComponent.vue?vue&type=script&lang=js&"
import style0 from "./ResponseComponent.vue?vue&type=style&index=0&id=32ac3d71&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ac3d71",
  null
  
)

export default component.exports