<template>
    <div>
        <confirm-component v-if="confirmDel" :id="idUser" message="Deseja realmente excluir o registro " @confirm="delUser()" @cancel="cancel()" />
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/callcenter">Central de Atendimento</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Usuários</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-8">
                        <h4 class="mt-1">{{ total + ' Usuários' }}</h4>   
                    </div>

                    <div class="col-4">
                        <router-link :to="`/callcenter/create/${callcenter.id}/user`" class="btn btn-success" style="float:right;">+ Usuário</router-link>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <p>{{ title }}</p>
                <table class="table table-hover mt-5">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nome</th>
                            <th class="text-center">Ações</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(user, index) in users.data" :key="index">
                            <td class="pt-3 size">{{ user.id }}</td>
                            <td class="pt-3 size">{{ user.user_name }}</td>
                            <td class="text-center">
                                <a href="" class="btn btn-danger" @click.prevent="confirm('DEL', user.id)" title="excluir"><i class="fas fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="users.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(users.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="users.current_page + 1 <= users.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(users.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p class="text-center">{{ 'Página ' + users.current_page + ' / ' + users.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import axios from 'axios'

import MessageComponent from './../snippets/MessageComponent'
import LoadingComponent from '../snippets/LoadingComponent'
import ConfirmComponent from './../snippets/ConfirmComponent'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token',
    ],
    data(){
        return{

            title: 'Usuários',
            users:{
                data:{},
                current_page: 1,
                last_page: 1,
            },
            id: this.$route.params.id,
            callcenter: {},
            preloader: false,
            confirmDel: false,
            idUser: 0,
            error: false,
            message: false,
            total: ''
        }
    },
    created(){

        this.getCallCenter()
        this.getUsers()
    },
    methods:{

        getCallCenter(){

            this.preloader = true

            axios.post(this.url + '/callcenters/edit', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.callcenter = response.data
                this.title = response.data.name

            }, error => {

                this.callcenter = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        getUsers(){

            this.preloader = true

            axios.post(this.url + '/callcenters-users', {page: this.users.current_page, callcenter: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.users.data = response.data.data
                this.users.last_page = response.data.last_page
                this.total = response.data.total

            }, error => {

                this.error = error
                
            }).finally(() => {

                this.preloader = false
            })
        },
        pagination(pageNumber)
        {
            this.callcenters.current_page = pageNumber
            this.getUsers()
        },
        confirm(type, id){

            this.idUser = id
            this.message = false

            if(type == 'DEL')
            {
                this.confirmDel = true
            }
        },
        delUser(){

            this.preloader = true 

            axios.post(this.url + '/callcenters-users/destroy', {id: this.idUser}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getUsers()

            }, error => {

                this.message = error 

            }).finally(() => {

                this.confirmDel = false
                this.idUser = 0
                this.preloader = false
            })
        },
        cancel(){

            this.confirmDel = false
            this.idUser = 0
        },
    },
}
</script>

<style scoped>
  .size 
  {
    font-size: 10pt;
  }
</style>