<template>
    <div>
        <confirm-component v-if="confirmDel" :id="idCallCenter" message="Deseja realmente excluir o registro " @confirm="delCallCenter()" @cancel="cancel()" />
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Centrais de Atendimento</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-8">
                        <h4 class="mt-1">{{ total + ' ' + title }}</h4>
                    </div>

                    <div class="col-4">
                        <router-link to="/callcenter/create" class="btn btn-success" style="float:right;">+ Central de Atendimento</router-link>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <table class="table table-hover mt-5">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nome</th>
                            <th class="text-center">Ações</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(callcenter, index) in callcenters.data" :key="index">
                            <td class="pt-3 size">{{ callcenter.id }}</td>
                            <td class="pt-3 size">{{ callcenter.name }}</td>
                            <td class="text-center">
                                <router-link class="btn btn-warning mr-1" :to="`/callcenter/${callcenter.id}/user`" title="Usuários"><i class="fas fa-user"></i></router-link>
                                <router-link class="btn btn-info mr-1" :to="`/callcenter/${callcenter.id}/edit`" title="Editar"><i class="fas fa-edit"></i></router-link>
                                <a href="" class="btn btn-danger" @click.prevent="confirm('DEL', callcenter.id)" title="excluir"><i class="fas fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="callcenters.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(callcenters.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="callcenters.current_page + 1 <= callcenters.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(callcenters.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p class="text-center">{{ 'Página ' + callcenters.current_page + ' / ' + callcenters.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import axios from 'axios'

import MessageComponent from './../snippets/MessageComponent'
import LoadingComponent from '../snippets/LoadingComponent'
import ConfirmComponent from './../snippets/ConfirmComponent'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token',
    ],
    data(){
        return{

            title: 'Centrais de Atendimento',
            callcenters:{
                data:{},
                current_page: 1,
                last_page: 1,
            },
            preloader: false,
            confirmDel: false,
            idCallCenter: 0,
            error: false,
            message: false,
            total: ''
        }
    },
    created(){

        this.getCallCenters()
    },
    methods:{

        getCallCenters(){

            this.preloader = true

            axios.post(this.url + '/callcenters', {page: this.callcenters.current_page}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.callcenters.data = response.data.data
                this.callcenters.last_page = response.data.last_page
                this.total = response.data.total

            }, error => {

                this.error = error
                
            }).finally(() => {

                this.preloader = false
            })
        },
        pagination(pageNumber)
        {
            this.callcenters.current_page = pageNumber
            this.getCallCenters()
        },
        confirm(type, id){

            
            this.idCallCenter = id
            this.message = false

            if(type == 'DEL')
            {
                this.confirmDel = true
            }
        },
        delCallCenter(){

            this.preloader = true 

            axios.post(this.url + '/callcenters/destroy', {id: this.idCallCenter}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getCallCenters()

            }, error => {

                this.message = error 

            }).finally(() => {

                this.confirm = false
                this.idCallCenter = 0
                this.preloader = false
            })
        },
        cancel(){

            this.confirmDel = false
            this.idCallCenter = 0
        },
    },
}
</script>

<style scoped>
  .size 
  {
    font-size: 10pt;
  }
</style>