<template>
    <div>
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/callcenter">Central de Atendimento</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" :to="`/callcenter/${id}/user`">Usuários</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Criar</a>
        </div>

        <div class="card">

            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>

            <div class="card-body">

                <p>{{ callcenter.name }}</p>

                <form class="app_form" @submit.prevent="saveCallCenterUser">

                    <div class="col-12">
                        <div class="row">

                            <select-component
                                :options="users" 
                                label="Usuário"
                                nameInput="user_name"
                                :valueInput="callcenterUser.user_name"
                                v-model="callcenterUser.user_name"
                                :message="message"
                                @vmodel="getModel"
                            />
                        </div>
                    </div>

                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success mt-2 align-right">Salvar Alterações</button>
                    </div>
                </form>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import LoadingComponent from '../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import SelectComponent from './../snippets/SelectComponent.vue'
import axios from 'axios'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        SelectComponent,
    },
    props: [
        'url',
        'token'
    ],
    data() {
    return{

        title: 'Vincular Usuário',
        callcenterUser:{
            user_id: '',
            user_name: '',
            call_center_id: '',
            call_center_name: '',
        },
        callcenter: {},
        users: {},
        message: {},
        preloader: false,
        id: this.$route.params.id,
    }
  },
  created(){

    this.getCallCenter()
    this.getUsers()
  },
  methods:{

        getModel(level){
            
            this.callcenterUser.user_id = level.id
            this.callcenterUser.user_name = level.name
            console.log(this.callcenterUser)
        },
        getCallCenter(){

            this.preloader = true

            axios.post(this.url + '/callcenters/edit', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.callcenter = response.data

                this.callcenterUser.call_center_id = response.data.id
                this.callcenterUser.call_center_name = response.data.name

            }, error => {

                this.callcenter = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        getUsers(){

            this.preloader = true

            let filters = ['administrador', 'técnico']
            let callcenter = this.id

            axios.post(this.url + '/users', {filters: filters, callcenter: callcenter}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.users = response.data

            }, error => {

                this.users = error.response
            }).finally(() => {

                this.preloader = false
            })
        },
        saveCallCenterUser(){

            this.preloader = true
            
            axios.post(this.url + '/callcenters-users/store', this.callcenterUser, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.$router.push(`/callcenter/${this.id}/user`)
                }

            }, error => {

                this.message = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
    },  
}
</script>

<style scoped>
    ul 
    {
        list-style: none;
    }
    .error 
    {
        color: red;
    }
</style>