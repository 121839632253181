import OSCookie from 'js-cookie'

export default{

    auth(to, from, next){

        const token = OSCookie.get('_os_token')

        if(!token)
        {
            next('/')
        }

        next()
    }
}