<template>
  <div>

    <message-component v-if="message" :message="message" />

    <!-- BREADCRUMB -->
    <div class="mt-4 mb-3">
      <router-link class="bc" to="/home">Home</router-link>
      <span class="separator"> > </span>
      <router-link class="bc" to="/sector">Setores</router-link>
      <span class="separator"> > </span>
      <a href="javascript:void(0)" class="bc-active">Criar</a>
    </div>
    
    <div class="card">
      <div class="card-header">
          <h4 class="text-center mt-1" v-if="!id">{{ titleCreate }}</h4>
          <h4 class="text-center mt-1" v-if="id">{{ titleEdit }}</h4>
      </div>

      <div class="card-body">
        <form class="app_form" @submit.prevent="saveSector">
          <div class="col-12">
            <div class="row">
                <!-- LINHA 01 -->
                <div class="form-group col-12">
                    <span style="color: #999; font-weight: bold;">Nome</span>
                    <input class="form-control" type="text" v-model="sector.name">
                    <small class="error" v-if="message.type == 'error'" v-text="message.message.name"></small>
                </div>
            </div>
          </div>

          <div class="col-12 text-right">
              <button type="submit" class="btn btn-success mt-2 align-right">Salvar Alterações</button>
          </div>
        </form>
      </div>
    </div>

    <loading-component v-if="preloader" />
  </div>
</template>

<script>

import OSCookie from 'js-cookie'
import LoadingComponent from '../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import axios from 'axios'

export default {
  components: { 
    MessageComponent,
    LoadingComponent, 
  },
  
  props: [
    'url',
    'token'
  ],
  data() {
    return{

      titleCreate: 'Cadastro de Setor',
      titleEdit: 'Edição de Setor',
      sector:{
        call_center_id: OSCookie.get('_locale_id'),
        name: '',
      },
      message:{},
      preloader: false,
      id: this.$route.params.id
    }
  },
  created(){

    if(this.id){

        this.getSector()
    }
  },
  methods:{

    getSector(){

      this.preloader = true

      axios.post(this.url + '/sectors/edit', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

        this.sector = response.data

      }, error => {

          this.sector = error.response

      }).finally(() => {

          this.preloader = false
      })
    },
    saveSector(){

      if(!this.id)
      {
          this.createSector()
      }
      else
      {
          this.editSector()
      }
    },
    createSector(){

        this.preloader = true
            
        axios.post(this.url + '/sectors/store', this.sector, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

            this.message = response.data

            if(this.message.type != 'error' && this.message.type != 'danger')
            {
                this.$router.push('/sector')
            }
        }, error => {

          this.message = error.response
          
        }).finally(() => {

          this.preloader = false
        })
    },
    editSector(){

      this.preloader = true

      let data = {id: this.id, sector: this.sector}
      
      axios.put(this.url + '/sectors/update', data, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

          this.message = response.data

          if(this.message.type != 'error' && this.message.type != 'warning' && this.message.type != 'danger')
          {
              this.$router.push('/sector')
          }

      }, error => {

          this.message = error.response

      }).finally(() => {

          this.preloader = false
      })
    },
  }
}
</script>

<style scoped>
  .error 
  {
      color: red;
  }
</style>