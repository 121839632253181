import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './routes/index'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueQuillEditor)

const router = new VueRouter({

  mode: 'history',
  routes
})

new Vue({

  el: '#app',
  router,
  render: h => h(App),
})
