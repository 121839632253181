<template>
    <div>
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/request">Solicitações</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Criar</a>
        </div>

        <div class="card">

            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>

            <div class="card-body">

                <form class="app_form" @submit.prevent="saveRequest">

                    <!-- LINHA 1 -->
                    <div class="col-12">
                        <div class="row">

                            <select-component
                                :options="callcenters" 
                                label="Central de Atendimento"
                                nameInput="call_center_name"
                                :valueInput="request.call_center_name"
                                v-model="request.call_center_name"
                                :message="message"
                                @vmodel="getModel"
                            />
                        </div>
                    </div>
                    <!-- LINHA 2 -->
                    <div class="form-group col-12">
                        <span style="color: #999; font-weight: bold;">Título</span>
                        <input class="form-control" type="text" v-model="request.title">
                        <small class="error" v-if="message.type == 'error'" v-text="message.message.title"></small>
                    </div>
                    <!-- LINHA 3 -->
                    <div class="form-group col-12">
                        <span style="color: #999; font-weight: bold;">Descrição</span>
                        <vue2-tinymce-editor 
                            v-model="request.description"
                            :options="tinymceOptions"
                        />
                        <small class="error" v-if="message.type == 'error'" v-text="message.message.description"></small>
                    </div>

                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success mt-2 align-right">Salvar Alterações</button>
                    </div>
                </form>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import LoadingComponent from '../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import SelectComponent from './../snippets/SelectComponent.vue'
import {Vue2TinymceEditor} from 'vue2-tinymce-editor' 
import axios from 'axios'
import OSCookie from 'js-cookie'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        SelectComponent,
        Vue2TinymceEditor,
    },
    props: [
        'url',
        'token'
    ],
    data() {
    return{

        title: 'Criar Solicitação',
        request:{
            user_id: OSCookie.get('_userid'),
            call_center_id: '',
            sub_sector_id: OSCookie.get('_locale_id'),
            user_name: OSCookie.get('_username'),
            call_center_name: '',
            sub_sector_name: OSCookie.get('_locale_name'),
            title: '',
            description: '',
            year: OSCookie.get('_year'),
        },
        callcenters: {},
        message: {},
        preloader: false,
        id: this.$route.params.id,
        tinymceOptions: {
            plugins: ['advlist autolink link image lists print preview hr searchreplace wordcount fullscreen insertdatetime media save table paste emoticons'],
        },
    }
  },
  created(){

    this.getCallCenters()

    if(this.id)
    {
        this.title = 'Editar Solicitação'
        this.getRequest()
    }
  },
  methods:{

        getModel(model){
            
            this.request.call_center_id = model.id
            this.request.call_center_name = model.name
        },
        getCallCenters(){

            this.preloader = true

            axios.post(this.url + '/requests/callcenters', {}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.callcenters = response.data

            }, error => {

                this.callcenters = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        getRequest(){

            this.preloader = true

            axios.post(this.url + '/requests/edit', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                response.data.description = ''
                this.request = response.data

            }, error => {

                this.request = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        saveRequest(){

            if(!this.id)
            {
                this.createRequest()
            }
            else
            {
                this.editRequest()
            }
        },
        createRequest(){

            console.log(this.request)

            this.preloader = true
            
            axios.post(this.url + '/requests/store', this.request, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.$router.push("/request")
                }

            }, error => {

                this.message = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        editRequest(){

            this.preloader = true

            let data = {id: this.id, data: this.request}
            
            axios.put(this.url + '/requests/update', data, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.$router.push('/request')
                }

            }, error => {

                this.message = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
    },  
}
</script>

<style scoped>
    ul 
    {
        list-style: none;
    }
    .error 
    {
        color: red;
    }
</style>