<template>
    <div>
        <confirm-component v-if="confirmDel" :id="idUser" message="Deseja realmente excluir o registro " @confirm="delUser()" @cancel="cancel()" />
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Usuários</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12">
                        <h4 class="mt-1">{{ total + ' ' + title }}</h4>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <table class="table table-hover mt-5">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Acesso</th>
                            <th class="text-center">Ações</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(user, index) in users.data" :key="index">
                            <td class="pt-3 size">{{ user.id }}</td>
                            <td class="pt-3 size">{{ user.name }}</td>
                            <td class="pt-3 size">{{ user.email }}</td>
                            <td class="pt-3 size">{{ user.access[0].toUpperCase() + user.access.substr(1) }}</td>
                            <td class="text-center">
                                <a v-if="user.status == 'ACTIVE'" @click.prevent="status(user.id, user.status)" href="javascript:void(0)" class="btn btn-success  mr-1" title="Desativar"><i class="far fa-play-circle"></i></a>
                                <a v-if="user.status == 'INATIVE'" @click.prevent="status(user.id, user.status)" href="javascript:void(0)" class="btn btn-secondary  mr-1" title="Ativar"><i class="far fa-play-circle"></i></a>
                                <router-link class="btn btn-warning mr-1" :to="`/user/${user.id}/level`" title="Níveis"><i class="fas fa-key"></i></router-link>
                                <a href="" class="btn btn-danger" @click.prevent="confirm('DEL', user.id)" title="excluir"><i class="fas fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="users.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(users.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="users.current_page + 1 <= users.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(users.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p class="text-center">{{ 'Página ' + users.current_page + ' / ' + users.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import OSCookie from 'js-cookie'
import axios from 'axios'

import MessageComponent from './../snippets/MessageComponent.vue'
import LoadingComponent from './../snippets/LoadingComponent.vue'
import ConfirmComponent from './../snippets/ConfirmComponent'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{

            title: 'Usuários',
            users:{
                data:{},
                current_page: 1,
                last_page: 1,
            },
            preloader: false,
            confirmDel: false,
            idUser: 0,
            error: false,
            message: false,
            currentId: OSCookie.get('_userid'),
            total: '',
            userAccess: OSCookie.get('_useraccess'),
            localeId: OSCookie.get('_locale_id'),
        }
    },
    created(){

        this.getUsers()
    },
    methods:{

        getUsers(){

            this.preloader = true

            axios.post(this.url + '/users', {access: this.userAccess, page: this.users.current_page}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.users.data = response.data.data
                this.users.last_page = response.data.last_page
                this.total = response.data.total

            }, error => {

                this.error = error
                
            }).finally(() => {

                this.preloader = false
            })
        },
        status(id, status){

            let data = {id: id, locale: this.localeId, currentId: this.currentId, status: status}

            axios.post(this.url + '/users/status', data, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getUsers()
            })
        },
        pagination(pageNumber)
        {
            this.users.current_page = pageNumber
            this.getSectors()
        },
        confirm(type, id){

            this.idUser = id
            this.message = false

            if(type == 'DEL')
            {
                this.confirmDel = true
            }
        },
        delUser(){

            this.preloader = true 

            axios.post(this.url + '/users/destroy', {id: this.idUser, locale: this.localeId, currentId: this.currentId}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getUsers()
            }, error => {

                this.message = error 
            }).finally(() => {

                this.confirmDel = false
                this.idUser = 0
                this.preloader = false
            })
        },
        cancel(){

            this.confirmDel = false
            this.idUser = 0
        },
    },
}
</script>

<style scoped>
  .size 
  {
    font-size: 10pt;
  }
</style>