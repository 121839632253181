<template>
    <div>
        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/response">Solicitações</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Relatório</a>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>
            <div class="card-body">
                <table id="table" style="width: 213mm; font-size: 9pt;">
                    <thead>
                        <tr style="font-size: 8pt;">
                            <td colspan="2">OS Nº:&nbsp;&nbsp;&nbsp;<b>{{ leftPad(request.id, 5) }}</b></td>
                            <td colspan="3">REMETENTE:&nbsp;&nbsp;&nbsp;<b>{{ request.sub_sector_name }}</b></td>
                        </tr>
                        <tr style="font-size: 8pt;">
                            <td colspan="2">SOLICITANTE:&nbsp;&nbsp;&nbsp;<b>{{ request.user_name }}</b></td>
                            <td colspan="3">DESTINATÁRIO:&nbsp;&nbsp;&nbsp;<b>{{ request.call_center_name }}</b></td>
                        </tr>
                        <tr style="font-size: 8pt;">
                            <td colspan="2">TÉCNICO:&nbsp;&nbsp;&nbsp;<b>{{ modelResponse.user_name ? modelResponse.user_name.toUpperCase() : '' }}</b></td>
                            <td colspan="3">TÍTULO:&nbsp;&nbsp;&nbsp;<b>{{ request.title }}</b></td>
                        </tr>
                        <tr style="font-size: 8pt;">
                            <td colspan="2">CRIADO EM:&nbsp;&nbsp;&nbsp;<b>{{ moment(request.created_at).format('DD/MM/yyyy HH:mm') }}</b></td>
                            <td colspan="3">DEVOLVIDO EM:&nbsp;&nbsp;&nbsp;<b>{{ moment(modelResponse.updated_at).format('DD/MM/yyyy HH:mm') }}</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="5">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="font-size: 10pt; background: #eee;" colspan="5"><b>Solicitação</b></td>
                        </tr>
                        <tr>
                            <td class="pt-3" style="text-align: justify;" colspan="5" v-html="request.description"></td>
                        </tr>
                        <tr>
                            <td style="font-size: 10pt; background: #eee;" colspan="5"><b>Resposta</b></td>
                        </tr>
                        <tr>
                            <td class="pt-3" style="text-align: justify; padding-bottom: 20mm;" colspan="5" v-html="modelResponse.description"></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%; border-top: solid 0.5px; text-align: center;">{{ request.user_name }}</td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%; border-top: solid 0.5px; text-align: center;">{{ modelResponse.user_name ? modelResponse.user_name.toUpperCase() : '' }}</td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </tfoot>
                </table>
                <div class="text-center mt-5" style="width: 213mm;">
                    <form class="app_form" @submit.prevent="generatePdf">
                        <button type="submit" class="btn btn-success mt-2">Gerar PDF</button>
                    </form> 
                </div>
            </div>
        </div>
        
        <loading-component v-if="preloader"/>
    </div>
</template>

<script>

import jsPDF from 'jspdf'
import axios from 'axios'
import moment from 'moment'

import LoadingComponent from './../../components/snippets/LoadingComponent.vue'

export default {
    components:{
        LoadingComponent,
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{
            title: 'Relatório',
            doc: new jsPDF("l", 'pt', 'a4'),
            rows: {},
            preloader: false,
            id: this.$route.params.id,
            modelResponse: {description: ''},
            request: {},
            moment: moment,
        }
    },
    created(){

        this.getRequest()
        this.getResponse()
    },
    methods:{
        getRequest(){

            this.preloader = true

            axios.post(this.url + '/requests/find', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.request = response.data

            }, error => {

                console.log(error)

            }).finally(() => {

                this.preloader = false
            })
        },
        getResponse(){

            axios.post(this.url + '/responses', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.modelResponse = response.data
            })
        },
        generatePdf(){

            let content = document.getElementById('table')

            this.doc.html(content, {

                callback: function(doc){

                    doc.output("dataurlnewwindow")
                },
                x: 20,
                y: 15,
            })

            // this.doc.output("dataurlnewwindow")

            this.$router.push('/response')
        },
        leftPad(value, totalWidth, paddingChar){

            let val = (value ? value.toString().length : 0)

            let length = totalWidth - val + 1;

            return Array(length).join(paddingChar || '0') + value;
        },
    },
}
</script>