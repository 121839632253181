<template>
    <div>
        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/">Login</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Criar</a>
        </div>

        <message-component v-if="message" :message="message" />

        <div class="card">
            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>

            <div class="card-body">
                <form class="app_form" @submit.prevent="createUser">

                    <div class="col-12">
                        <div class="row">
                            <!-- {{-- LINHA 01 --}} -->
                            <div class="form-group col-12">
                                <span style="color: #999; font-weight: bold;">Nome</span>
                                <input class="form-control" type="text" :readonly="validated" v-model="user.name">
                            </div>
                            <!-- {{-- LINHA 02 --}} -->
                            <div class="form-group col-12">
                                <span style="color: #999; font-weight: bold;">Email</span>
                                <input class="form-control" type="email" :readonly="validated" v-model="user.email">
                            </div>
                            <div class="form-group col-12 col-lg-6">
                                <span style="color: #999; font-weight: bold;">Senha</span>
                                <input class="form-control" type="password" v-model="user.password">
                            </div>
                            <div class="form-group col-12 col-lg-6">
                                <span style="color: #999; font-weight: bold;">Repetir Senha</span>
                                <input class="form-control" type="password" v-model="user.confirm_password">
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success mt-2 align-right">Salvar Alterações</button>
                    </div>
                </form>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>
import LoadingComponent from './../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import axios from 'axios'

export default {
    components: { 
      LoadingComponent,
      MessageComponent, 
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{

            title: 'Cadastro de Usuário',
            user:{
                name: '',
                email: '',
                password: '',
                confirm_password: '',
            },
            message: {},
            preloader: false,
            validated: false,
        }
    },
    methods:{
        createUser(){

            this.preloader = true
                
            axios.post(this.url + '/users/store', this.user, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type == 'message')
                {
                    this.$router.push('/')
                }
            }, error => {

                console.log(error)

            }).finally(() => {

                this.preloader = false
            })
        },
    },
}
</script>

<style scoped>

    .error 
    {
        color: red;
    }
</style>