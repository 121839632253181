<template>
    <li class="sidebar-dropdown">
        <a href="javascript:void(0)">
            <i :class="icon"></i>
            <span>{{label}}</span>
        </a>
        <div class="sidebar-submenu">
            <ul>
            <li>
                <router-link :to="`${route}`">Listar</router-link>
            </li>
            </ul>
        </div>
    </li>
</template>

<script>
export default {
    props:[
        'icon',
        'label',
        'route',
    ]
}
</script>