<template>
    <div>
        <confirm-component v-if="confirmDel" :id="idSubSector" message="Deseja realmente excluir o registro " @confirm="delSubSector()" @cancel="cancel()" />
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">SubSetores</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-8">
                        <h4 class="mt-1">{{ total + ' ' + title }}</h4>
                    </div>

                    <div class="col-4">
                        <router-link to="/subsector/create" class="btn btn-success" style="float: right;">+ SubSetor</router-link>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <table class="table table-hover mt-5">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nome</th>
                            <th>Setor</th>
                            <th class="text-center">Ações</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(subsector, index) in subsectors.data" :key="index">
                            <td class="pt-3 size">{{ subsector.id }}</td>
                            <td class="pt-3 size">{{ subsector.name }}</td>
                            <td class="pt-3 size">{{ subsector.sector_name }}</td>
                            <td class="text-center">
                                <router-link class="btn btn-warning mr-1" :to="`/subsector/${subsector.id}/user`" title="Usuários"><i class="fas fa-user"></i></router-link>
                                <router-link class="btn btn-info mr-1" :to="`/subsector/${subsector.id}/edit`" title="Editar"><i class="fas fa-edit"></i></router-link>
                                <a href="" class="btn btn-danger" @click.prevent="confirm('DEL', subsector.id)" title="excluir"><i class="fas fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="subsectors.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(subsectors.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="subsectors.current_page + 1 <= subsectors.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(subsectors.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p class="text-center">{{ 'Página ' + subsectors.current_page + ' / ' + subsectors.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import OSCookie from 'js-cookie'
import axios from 'axios'

import MessageComponent from './../snippets/MessageComponent.vue'
import LoadingComponent from './../snippets/LoadingComponent.vue'
import ConfirmComponent from './../snippets/ConfirmComponent'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{

            title: 'SubSetores',
            subsectors:{
                data:{},
                current_page: 1,
                last_page: 1,
            },
            preloader: false,
            confirmDel: false,
            idSubSector: 0,
            error: false,
            message: false,
            total: '',
            localeId: OSCookie.get('_locale_id'),
        }
    },
    created(){

        this.getSubSectors()
    },
    methods:{

        getSubSectors(){

            this.preloader = true

            axios.post(this.url + '/subsectors', {locale: this.localeId, page: this.subsectors.current_page}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.subsectors.data = response.data.data
                this.subsectors.last_page = response.data.last_page
                this.total = response.data.total

            }, error => {

                this.error = error
                
            }).finally(() => {

                this.preloader = false
            })
        },
        pagination(pageNumber)
        {
            this.subsectors.current_page = pageNumber
            this.getSubSectors()
        },
        confirm(type, id){

            this.idSubSector = id
            this.message = false

            if(type == 'DEL')
            {
                this.confirmDel = true
            }
        },
        delSubSector(){

            this.preloader = true 

            axios.post(this.url + '/subsectors/destroy', {id: this.idSubSector}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getSubSectors()

            }, error => {

                this.message = error 
            }).finally(() => {

                this.confirmDel = false
                this.idSubSector = 0
                this.preloader = false
            })
        },
        cancel(){

            this.confirmDel = false
            this.idSubSector = 0
        },
    },
}
</script>

<style scoped>
  .size 
  {
    font-size: 10pt;
  }
</style>