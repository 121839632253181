<template>
    <div>
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/request">Solicitações</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Assinar</a>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>

            <div class="card-body">
                <table>
                    <tbody>
                        <tr style="font-size: 10pt;">
                            <td style="width: 110px;">SOLICITANTE:</td>
                            <td><b>{{ request.user_name }}</b></td>
                        </tr>
                        <tr style="font-size: 10pt;">
                            <td>DESTINATÁRIO:</td>
                            <td><b>{{ request.call_center_name }}</b></td>
                        </tr>
                        <tr style="font-size: 10pt;">
                            <td>TÍTULO:</td>
                            <td><b>{{ request.title }}</b></td>
                        </tr>
                        <tr>
                            <td style="font-size: 10pt;">CRIADO:</td>
                            <td><b>{{ moment(request.created_at).format('DD/MM/yyyy HH:mm') }}</b></td>
                        </tr>
                        <tr>
                            <td class="pt-3" style="text-align: justify;" colspan="2" v-html="request.description"></td>
                        </tr>
                        <tr>
                            <td style="font-size: 10pt;">SOLUÇÃO:</td>
                        </tr>
                        <tr>
                            <td class="pt-3" style="text-align: justify;" colspan="2" v-html="modelResponse"></td>
                        </tr>
                    </tbody>
                </table>

                <form class="app_form" @submit.prevent="updateConcluded" enctype="multipart/form-data">

                    <!-- LINHA 1 -->
                    <input type="hidden" v-model="modelRequest.concluded">

                    <button type="submit" class="btn btn-success mt-2" style="float:right;">Concluir Processo</button>
                </form> 
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import LoadingComponent from '../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import axios from 'axios'
import moment from 'moment'
import OSCookie from 'js-cookie'

export default {
    components:{
        LoadingComponent,
        MessageComponent,
    },
    props: [
        'url',
        'token',
    ],
    data(){
        return{
            title: 'Conclusão',
            request: {},
            modelResponse: '',
            modelRequest: {
                concluded: 'yes',
            },
            message: {},
            preloader: false,
            idRequest: this.$route.params.id,
            moment: moment,
            id: 0,
        }
    },
    created(){

        this.getRequest()
        this.getResponse()
        this.countNotifys()
    },
    methods:{
        getRequest(){

            this.preloader = true

            axios.post(this.url + '/requests/find', {id: this.idRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.request = response.data

            }, error => {

                console.log(error)

            }).finally(() => {

                this.preloader = false
            })
        },
        getResponse(){

            axios.post(this.url + '/responses', {id: this.idRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                if(response.data)
                {
                    this.id = response.data.id
                    this.modelResponse = response.data.description
                }
            })
        },
        updateConcluded(){

            this.preloader = true

            axios.put(this.url + '/requests/concluded', {id: this.request.id, data: this.modelRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.$router.push("/request")
                }
                
            }, error => {

                this.message = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        countNotifys(){

            let locale = OSCookie.get('_locale_id')

            axios.post(this.url + '/responses/notifications', {locale: locale}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                let count = response.data
                this.$emit('notifys', count)
            })
        },
    },
}
</script>

<style scoped>
    .error 
    {
        color: red;
    }
</style>