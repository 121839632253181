<template>
    <div class="alert alert-warning text-center">
      <p>{{ message }} <b>{{ id }}</b>?</p>
      <hr>
      <button class="btn btn-success mr-1" @click="confirm()">Sim</button>
      <button class="btn btn-danger" @click="cancel()">Não</button>
    </div>
</template>

<script>
export default {
    props: [
        'id',
        'message',
    ],
    methods:{
        confirm(){

            this.$emit('confirm')
        },
        cancel(){

            this.$emit('cancel')
        }
    }
}
</script>