<template>
    <div>
        <confirm-component v-if="confirmYear" :id="nameYear" message="Deseja realmente acessar o ano " @confirm="access()" @cancel="cancel()" />
        <confirm-component v-else-if="confirmDel" :id="idYear" message="Deseja realmente excluir o registro " @confirm="delYear()" @cancel="cancel()" />

        <message-component v-if="message" :message="message"/>

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Anos</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-8 col-lg-9">
                        <h4 class="mt-1">{{ title }}</h4>
                    </div>

                    <div class="col-4 col-lg-3">
                        <router-link to="year/create" class="btn btn-success" style="float: right;">+ Ano</router-link>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <!-- TABELA DE ANOS -->
                <table class="table table-hover">
                    <thead class="size">
                        <tr>
                        <th>#</th>
                        <th>ANO</th>
                        <th class="text-center">AÇÕES</th>
                        </tr>
                    </thead>

                    <tbody class="size">
                        <tr v-for="(year, index) in years.data" :key="index">
                            <td class="pt-3">{{ year.id }}</td>
                            <td class="pt-3">{{ year.name }}</td>
                            <td class="text-center">
                                <!-- <a href="" class="btn btn-success mr-1" @click.prevent="access(year.name)" title="Acessar"><i class="fas fa-sign-in-alt"></i></a> -->
                                <a href="" class="btn btn-success mr-1" @click.prevent="confirm('YEAR', year.name)" title="Acessar"><i class="fas fa-sign-in-alt"></i></a>
                                <a href="" class="btn btn-danger" @click.prevent="confirm('DEL', year.name)" title="Excluir"><i class="fas fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="years.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(years.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="years.current_page + 1 <= years.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(years.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p class="text-center">{{ 'Página ' + years.current_page + ' / ' + years.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>
import OSCookie from 'js-cookie'
import axios from 'axios'
import MessageComponent from '../snippets/MessageComponent'
import LoadingComponent from '../snippets/LoadingComponent'
import ConfirmComponent from './../snippets/ConfirmComponent'

export default {
    components:{

        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{
            title: 'Exercícios',
            confirmDel: false,
            confirmYear: false,
            idYear: 0,
            nameYear: '',
            message: false,
            years:{
                data: {},
                current_page: 1,
                last_page: 1,
            },
            preloader: false,
            error: false,
        }
    },
    created(){

        this.getYears()
    },
    methods:{

        getYears()
        {
            this.preloader = true

            axios.post(this.url + '/years', {}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.years.data = response.data.data
                this.years.last_page = response.data.last_page

            }, error => {

                this.error = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        pagination(pageNumber){

            this.years.current_page = pageNumber
            this.getYears()
        },
        confirm(type, param){

            this.message = false

            if(type == 'DEL')
            {
                this.confirmDel = true
                this.idYear = param
            }
            else if(type == 'YEAR')
            {
                this.confirmYear = true
                this.nameYear = param
            }
        },
        delYear(){

            this.preloader = true 
            let current = OSCookie.get('_year');

            axios.post(this.url + '/years/destroy', {year: this.idYear, year_current: current}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getYears()

            }, error => {

                this.message = error 
            }).finally(() => {

                this.confirmDel = false
                this.idYear = 0
                this.preloader = false
            })
        },
        cancel(){
            
            this.confirmDel = false
            this.confirmYear = false
            this.idYear = 0
            this.nameYear = 0
        },
        access(){

            OSCookie.set('_year', this.nameYear)
            this.$router.go(0)
        },
    },
}
</script>

<style scoped>
    .size 
    {
        font-size: 10pt;
    }
</style>