<template>
    <div>
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/user">Users</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Nível</a>
        </div>

        <div class="card">

            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>

            <div class="card-body">
                <form class="app_form">

                    <div class="col-12">
                        <div class="row">

                            <select-component
                                :options="levels" 
                                label="Nível"
                                nameInput="name"
                                :valueInput="user.access"
                                v-model="user.access"
                                :message="message"
                                @vmodel="getModel"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import OSCookie from 'js-cookie'
import LoadingComponent from '../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import SelectComponent from './../snippets/SelectComponent.vue'
import axios from 'axios'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        SelectComponent,
    },
    props: [
        'url',
        'token'
    ],
    data() {
    return{

        title: '',
        user:{
            name: '',
            access: '',
        },
        level:{
            name: ''
        },
        message: {},
        preloader: false,
        id: this.$route.params.user,
        levels:[],
        userAccess: OSCookie.get('_useraccess'),
        localeId: OSCookie.get('_locale_id'),
    }
  },
  created(){

      if(this.userAccess == 'administrador')
      {
          this.levels.push({name: 'Adminstrador'})
      }

      this.levels.push({name: 'Técnico'})
      this.levels.push({name: 'Solicitante'})

    this.getUser()
  },
  methods:{

        getModel(level){
            
            this.user.access = level.name
            this.editUser()
        },
        getUser(){

            this.preloader = true

            axios.post(this.url + '/users/edit', {id: this.id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.user = response.data
                this.title = this.user.name

            }, error => {

                this.subsector = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        editUser(){

            this.preloader = true

            let data = {id: this.id, locale: this.localeId, access: this.user.access}
            
            axios.put(this.url + '/users/update', data, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    if(OSCookie.get('_userid') == this.id)
                    {
                        OSCookie.set('_useraccess', this.user.access)
                    } 
                    
                    this.$router.go(0)
                }

            }, error => {

                this.message = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
    },  
}
</script>

<style scoped>
    ul 
    {
        list-style: none;
    }
    .error 
    {
        color: red;
    }
</style>