<template>
    <div>
        <confirm-component v-if="confirmDel" :id="idRequest" message="Deseja realmente excluir o registro " @confirm="delRequest()" @cancel="cancel()" />
        <confirm-component v-else-if="confirmSent" :id="idRequest" message="Deseja realmente enviar a solicitação nº " @confirm="sentUpdateRequest()" @cancel="cancel()" />
        
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Solicitações</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-8">
                        <h4 class="mt-1">{{ total + ' ' + title }}</h4>
                    </div>

                    <div class="col-4">
                        <a href="" @click.prevent="createRequest" class="btn" :class="create ? 'btn-success' : 'btn-secondary'" style="float:right;">+ Solicitação</a>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <table class="table table-hover mt-5">
                    <thead>
                        <tr>
                            <th>Título</th>
                            <th>Destinatário</th>
                            <th>Solicitado</th>
                            <th class="text-center">Ações</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(request, index) in requests.data" :key="index" :style="viewed(request) ? 'color: #000;' : 'color: #888;'">
                            <td class="pt-3 size">{{ request.title }}</td>
                            <td class="pt-3 size">{{ request.call_center_name }}</td>
                            <td class="pt-3 size">{{ moment(request.created_at).format('DD/MM/yyyy HH:mm') }}</td>
                            <td class="text-center">
                                <a href="" @click.prevent="confirm('SENT', request.id)" class="mr-1 mb-1" :class="sent(request) ? 'btn btn-success' : 'btn btn-secondary'" :title="sent(request) ? 'Enviar' : 'Enviado'"><i class="fas fa-envelope"></i></a>
                                <a href="" @click.prevent="beforeConcluded(request.id)" class="btn mr-1 mb-1" :class="viewed(request) || tecSee(request) ? 'btn-success' : 'btn-secondary'" :title="viewed(request) ? 'Concluir' : 'Concluído'"><i class="fas fa-stop-circle"></i></a>
                                <a href="" @click.prevent="editRequest(request.id)" class="btn mr-1 mb-1" :class="sent(request) ? 'btn-info' : 'btn-secondary'" title="Editar"><i class="fas fa-edit"></i></a>
                                <a href="" @click.prevent="confirm('DEL', request.id)" class="btn mb-1" :class="request.del == 1 ? 'btn-danger' : 'btn-secondary'" title="excluir"><i class="fas fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="requests.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(requests.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="requests.current_page + 1 <= requests.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(requests.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p class="text-center">{{ 'Página ' + requests.current_page + ' / ' + requests.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />

    </div>
</template>

<script>

import OSCookie from 'js-cookie'
import axios from 'axios'

import MessageComponent from './../snippets/MessageComponent'
import LoadingComponent from '../snippets/LoadingComponent'
import ConfirmComponent from './../snippets/ConfirmComponent'
import moment from 'moment'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token',
    ],
    data(){
        return{

            title: 'Solicitações',
            requests:{
                data:{},
                current_page: 1,
                last_page: 1,
            },
            preloader: false,
            confirmDel: false,
            confirmSent: false,
            idRequest: 0,
            error: false,
            message: false,
            total: '',
            moment: moment,
            filters: {user: OSCookie.get('_userid'), subsector: OSCookie.get('_locale_id'), year: OSCookie.get('_year')},
            create: false,
        }
    },
    created(){

        this.getRequests()
        this.beforeCreate()
        this.countMessages()
    },
    methods:{

        getRequests(){

            this.preloader = true

            axios.post(this.url + '/requests', {page: this.requests.current_page, filters: this.filters}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.requests.data = response.data.data
                this.requests.last_page = response.data.last_page
                this.total = response.data.total

            }, error => {

                this.error = error
                
            }).finally(() => {

                this.preloader = false
            })
        },
        pagination(pageNumber)
        {
            this.requests.current_page = pageNumber
            this.getRequests()
        },
        beforeCreate(){

            this.preloader = true

            axios.post(this.url + '/requests/before-create', {filters: this.filters}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.create = response.data
                console.log(this.create)

            }).finally(() => {

                this.preloader = false
            })
        },
        createRequest(){

            this.preloader = true

            axios.post(this.url + '/requests/create', {filters: this.filters}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                let message = response.data

                if(message.type != 'error'  && message.type != 'danger' && message.type != 'warning')
                {
                    this.$router.push('/request/create')
                }

                this.message = message

            }).finally(() => {

                this.preloader = false
            })
        },
        editRequest(id){

            axios.post(this.url + '/requests/edit', {id: id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                let message = response.data

                if(message.type != 'error'  && message.type != 'danger' && message.type != 'warning')
                {
                    this.$router.push(`/request/${id}/edit`)
                }

                this.message = message

            }).finally(() => {

                this.preloader = false
            })
        },
        sentUpdateRequest(){

            this.preloader = true

            axios.put(this.url + '/requests/update-sent', {id: this.idRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getRequests()
                this.$router.go(0)

            }, error => {

                this.message = error

            }).finally(() => {

                this.preloader = false
                this.confirmSent = false
            })
        },
        countMessages(){

            let locale = OSCookie.get('_locale_id')

            axios.post(this.url + '/responses/messages', {locale: locale}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                let count = response.data

                this.$emit('msgs', count)
            })
        },
        beforeConcluded(id){
            
            axios.put(this.url + '/requests/before-concluded', {id: id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message != 'error'  && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.$router.push(`/request/${id}/concluded`)
                }
            })
        },
        confirm(type, id){

            this.preloader = true

            axios.post(this.url + '/requests/confirm-sent', {type: type, id: id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message != 'error'  && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.idRequest = id
                    this.message = false

                    if(type == 'DEL')
                    {
                        this.confirmDel = true
                    }
                    else if(type == 'SENT')
                    {
                        this.confirmSent = true
                    }
                }
            }).finally(() => {

                this.preloader = false
            })
        },
        delRequest(){

            this.preloader = true 

            axios.post(this.url + '/requests/destroy', {id: this.idRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.getRequests()

            }, error => {

                this.message = error 

            }).finally(() => {

                this.idRequest = 0
                this.confirmDel = false
                this.confirmSent = false
                this.preloader = false
            })
        },
        cancel(){

            this.confirmDel = false
            this.confirmSent = false
            this.idRequest = 0
        },
        sent(request){

            if(request.sent == 'no' && request.read == 'yes' && request.response == 'yes' && request.viewed == 'yes' && request.concluded == 'yes'){

                return true
            }
            else{

                return false
            }
        },
        viewed(request){

            if(request.sent == 'yes' && request.read == 'yes' && request.response == 'yes' && request.viewed == 'no' && request.concluded == 'no'){

                return true
            }
            else{

                return false
            }
        },
        tecSee(request){

            if(request.sent == 'yes' && request.read == 'yes' && request.response == 'yes' && request.viewed == 'yes' && request.concluded == 'no'){

                return true
            }
            else{

                return false
            }
        },
    },
}
</script>

<style scoped>
  .size 
  {
    font-size: 10pt;
  }
</style>