<template>
    <div class="form-group col-12">
        <span style="color: #999; font-weight: bold;">{{ label }}</span>
        <div class="selected-item" @click="isVisible = !isVisible">
            <span v-if="!valueInput">{{ selectedItem.name }}</span>
            <span v-else>{{ valueInput }}</span>
            <i v-if="!isVisible" class="fas fa-angle-down"></i>
            <i v-if="isVisible" class="fas fa-angle-up"></i>
        </div>
        <div v-if="isVisible" class="col-12">
            <div class="dropdown-popover text-center">
                <input class="" type="text" @keyup="getInput" :placeholder="'Busca por ' + label">
                <div class="options">
                    <ul>
                        <li v-for="(option, index) in filteredOptions" :key="index" @click="selectItem(option)">{{ option.name }}</li>
                        <li v-if="filteredOptions.length <= 0">Nenhum Dado Disponível</li>
                    </ul>
                </div>
            </div>
        </div>
        <small class="error" v-if="message.type == 'error'" v-text="message.message[nameInput]"></small>
    </div>
</template>

<script>
export default {
    
    props: [
        'options',
        'label',
        'nameInput',
        'message',
        'valueInput',
    ],
    data(){
        return{
            searchQuery: '',
            selectedItem: {name: 'Selecione uma opção'},
            isVisible: false,
        }
    },
    computed:{
        filteredOptions(){

            const query = this.searchQuery.toLowerCase()

            if(this.searchQuery === "")
            {
                return this.options;
            }

            return this.options.filter(option => {

                return Object.values(option).some((word) => String(word).toLowerCase().includes(query))
            })
        },
    },
    methods:{
        getInput(event){

            this.searchQuery = event.target.value
        },
        selectItem(option){
            
            this.selectedItem = option
            this.isVisible = false
            this.searchQuery = ""
            this.$emit('vmodel', option)
        },
    },
}
</script>

<style scoped>
    
    .selected-item
    {
        cursor: pointer;
        height: 40px;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 5px 20px 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
    }
    .dropdown-popover
    {
        position: absolute;
        border: 1px solid lightgray;
        top: 5px;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 99;
        border-radius: 5px;
    }
    .dropdown-popover input 
    {
        width: 98%;
        height: 30px;
        border: 1px solid lightgray;
        font-size: 13px;
        padding: 20px 8px;
        margin-top: 10px;
        outline: none;
        border-radius: 3px;
    }
    .options ul
    {
        list-style: none;
        text-align: left;
        max-height: 200px;
        overflow-y: scroll;
        padding: 15px 0;
        width: 98%;
        margin-left: 1%;
        margin-top: 10px;
        background-color: #f1f1f1;
        border-radius: 5px;
    }
    .options ul li 
    {
        width: 98%;
        margin-left: 1%;
        padding: 8px; 
        cursor: pointer;
        font-size: 12px;
        color: #70878a;
        font-weight: bold;
    }
    .options ul li:hover 
    {
        color: #222;
        font-weight: bold;
    }
    .error 
    {
        color: red;
    }
</style>

