<template>
    <div>
        <div v-if="message.type == 'warning'" :class="message.color">
            {{ message.message[0] }}
        </div>

        <div v-if="message.type == 'danger' || message.type == 'message'" :class="message.color">
            {{ message.message[0] }}
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'type',
        'message',
    ],
}
</script>