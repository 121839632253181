<template>
    <div>
        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/year">Anos</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Criar</a>
        </div>

        <div class="card">
            <div class="card-header">
                <h4>{{ title }}</h4>
            </div>

            <div class="card-body">
                <form class="app_form" @submit.prevent="saveYear">
                    <div class="col-12">
                        <div class="row">
                            <!-- {{-- LINHA 01 --}} -->
                            <div class="form-group col-12">
                                <span style="color: #999; font-weight: bold;">Ano</span>
                                <input class="form-control" type="number" v-model="year.name">
                                <small class="error" v-if="message.type == 'error'" v-text="message.message.name"></small>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success mt-2 align-right">Salvar Alterações</button>
                    </div>
                </form>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import axios from 'axios'
import LoadingComponent from '../snippets/LoadingComponent.vue'

export default {
    components: { 
        LoadingComponent 
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{
            title: 'Cadastrar Ano',
            year:{
                name: '',
            },
            message: {
                status: '',
                type: ''
            },
            preloader: false,
        }
    },
    methods:{
        saveYear(){

            this.preloader = true
                
            axios.post(this.url + '/years/store', this.year, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message != 'warning')
                {
                    this.$router.push('/year')
                }

            }, error => {

                this.message = error.response
                
            }).finally(() => {

                this.preloader = false
            })
        },
    },
}
</script>

<style scoped>
    .error 
    {
        color: red;
    }
</style>