<template>
    <div>
        <confirm-component v-if="confirmSent" :id="idResponse" message="Deseja realmente enviar a solicitação nº " @confirm="sentUpdateResponse()" @cancel="cancel()" />

        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Solicitações</a>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12">
                        <h4 class="mt-1">{{ total + ' ' + title }}</h4>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <table class="table table-hover mt-5">
                    <thead>
                        <tr v-if="total">
                            <th>Título</th>
                            <th>Remetente</th>
                            <th>Solicitado</th>
                            <th class="text-center">Ações</th>
                        </tr>
                        <tr v-else>
                            <p class="text-center">Nenhuma solicitação encontrada</p>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(request, index) in requests.data" :key="index" :style="tecSee(request) ? 'color: #000;' : 'color: #888;'">
                            <td class="pt-3 size">{{ request.title }}</td>
                            <td class="pt-3 size">{{ request.sub_sector_name }}</td>
                            <td class="pt-3 size">{{ moment(request.created_at).format('DD/MM/yyyy HH:mm') }}</td>
                            <td class="text-center">
                                <a href="" @click.prevent="confirm('SENT', request.id)" class="mr-1" :class="tecSee(request) || tecResp(request) ? 'btn btn-success' : 'btn btn-secondary'" title="Enviar"><i class="fas fa-envelope"></i></a>
                                <router-link :to="`/report/${request.id}/atendimento`" class="btn btn-secondary mr-1" title="Gerar Pdf"><i class="fas fa-file-pdf"></i></router-link>
                                <a href="" @click.prevent="beforeResponse(request.id)" class="btn mr-1" :class="tecSee(request) || tecResp(request) ? 'btn-success' : 'btn-secondary'" :title="request.response == 'no' ? 'Responder' : 'Respondido'"><i class="fa fa-edit"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ul class="pagination">
                    <li class="page-item" v-if="requests.current_page - 1 >= 1">
                        <a href="" class="page-link" @click.prevent="pagination(requests.current_page - 1)">Anterior</a>
                    </li>

                    <li class="page-item" v-if="requests.current_page + 1 <= requests.last_page">
                        <a href="" class="page-link" @click.prevent="pagination(requests.current_page + 1)">Próximo</a>
                    </li>
                </ul>

                <p v-if="total" class="text-center">{{ 'Página ' + requests.current_page + ' / ' + requests.last_page }}</p>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import OSCookie from 'js-cookie'
import axios from 'axios'

import MessageComponent from './../snippets/MessageComponent'
import LoadingComponent from '../snippets/LoadingComponent'
import ConfirmComponent from './../snippets/ConfirmComponent'
import moment from 'moment'

export default {
    components:{
        MessageComponent,
        LoadingComponent,
        ConfirmComponent,
    },
    props: [
        'url',
        'token',
    ],
    data(){
        return{

            title: 'Solicitações',
            requests:{
                data:{},
                current_page: 1,
                last_page: 1,
            },
            preloader: false,
            error: false,
            message: false,
            total: '',
            moment: moment,
            id: this.$route.params.id,
            locale: OSCookie.get('_locale_id'),
            confirmSent: false,
            idRequest: 0,
        }
    },
    created(){

        this.getRequests()
        this.countMessages()
        this.queue()
    },
    methods:{

        getRequests(){

            this.preloader = true

            let year = OSCookie.get('_year')
            let locale = this.locale

            axios.post(this.url + '/responses/requests', {locale: locale, page: this.requests.current_page, year: year}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.requests.data = response.data.data
                this.requests.last_page = response.data.last_page
                this.total = response.data.total

            }, error => {

                this.error = error
                
            }).finally(() => {

                this.preloader = false
            })
        },
        pagination(pageNumber)
        {
            this.requests.current_page = pageNumber
            this.getResponses()
        },
        confirm(type, id){

            if(type == 'SENT'){

                this.confirmSent = true
                this.idRequest = id
            }
        },
        cancel(){

            this.confirmSent = false
            this.idRequest = 0
        },
        sentUpdateResponse(){

            this.preloader = true

            axios.put(this.url + '/responses/update-sent', {id: this.idRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data
                this.$router.go(0)

            }, error => {

                this.message = error

            }).finally(() => {

                this.preloader = false
                this.idRequest = 0
                this.confirmSent = false
            })
        },
        beforeResponse(id){

            axios.post(this.url + '/responses/before-store', {id: id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(() => {

                this.$router.push(`/response/${id}/create`)
            })
        },
        countMessages(){

            let locale = OSCookie.get('_locale_id')

            axios.post(this.url + '/requests/messages', {locale: locale}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                let count = response.data

                this.$emit('msgs', count)
            })
        },
        queue(){

            this.$emit('queue', 3)
        },
        tecSee(request){

            if(request.sent == 'yes' && request.read == 'no' && request.response == 'no' && request.viewed == 'yes' && request.concluded == 'yes'){

                return true
            }
            else{

                return false
            }
        },
        tecResp(request){

            if(request.sent == 'yes' && request.read == 'yes' && request.response == 'no' && request.viewed == 'yes' && request.concluded == 'yes'){

                return true
            }
            else{

                return false
            }
        },
    },
}
</script>

<style scoped>
  .size 
  {
    font-size: 10pt;
  }
</style>