<template>
    <div>
        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/">Login</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active">Recuperar</a>
        </div>

        <message-component v-if="message" :message="message" />

        <div class="card">
            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>

            <div class="card-body">
                <form class="app_form" @submit.prevent="recovery">

                    <div class="col-12">
                        <div class="row">
                            <!-- {{-- LINHA 01 --}} -->
                            <div class="col-12 col-lg-3"></div>
                            <div class="form-group col-12 col-lg-6">
                                <span style="color: #999; font-weight: bold;">Email</span>
                                <input class="form-control" type="email" v-model="user.email">
                            </div>
                            <div class="col-12 col-lg-3"></div>
                        </div>
                    </div>

                    <div class="col-12 text-right">
                        <button type="submit" class="btn btn-success mt-2 align-right">Enviar</button>
                    </div>
                </form>
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>
import LoadingComponent from './../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import axios from 'axios'

export default {
    components: { 
      LoadingComponent,
      MessageComponent, 
    },
    props: [
        'url',
        'token'
    ],
    data(){
        return{

            title: 'Recuperar Senha',
            user:{
                email: '',
            },
            message: {},
            preloader: false,
        }
    },
    methods:{
        recovery(){

            this.preloader = true
                
            axios.post(this.url + '/users/password/recovery/do', this.user).then(response => {

                this.message = response.data

            }, error => {

                console.log(error)

            }).finally(() => {

                this.preloader = false
            })
        },
    },
}
</script>

<style scoped>

    .error 
    {
        color: red;
    }
</style>