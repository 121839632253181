<template>
  <div id="app">
    <div class="page-wrapper chiller-theme" :class="online ? 'toggled' : ''">
      <a v-if="online" id="show-sidebar" class="btn btn-sm btn-dark" href="javascript:void(0)">
        <i class="fas fa-bars"></i>
      </a>
      <nav v-if="online" id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
          <div class="sidebar-brand text-center">
            <a href="#" style="font-size: 13pt;">{{ subtitle }}</a>
            <div id="close-sidebar">
              <i class="fas fa-window-close"></i>
            </div>
          </div>

          <router-link v-if="online" to="/">
            <div class="sidebar-header">
              <div class="user-pic">
                <img class="img-responsive img-rounded" src="./assets/img/user.png" alt="User picture">
              </div>
              <div class="user-info">
                
                  <span class="user-name">
                    {{ username.split(' ')[0] }}
                    <strong>{{ username.split(' ')[1] }}</strong>
                  </span>
                  <span class="user-role">{{ useraccess }}</span>
                  <span class="user-status">
                    <i class="fa fa-circle"></i>
                    <span>Online</span>
                  </span>
              </div>
            </div>
          </router-link>
          <!-- sidebar-header  -->
          <div class="sidebar-search">
            <div>
              <div class="input-group">
                <input type="text" class="form-control search-menu" placeholder="Search...">
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- sidebar-search  -->
          <div class="sidebar-menu">
            <ul>
              <li class="header-menu">
                <span>General</span>
              </li>

              <!-- Menus Principais do Sistema -->
              <div v-for="(menu, index) in menus" :key="index">
                <menu-component
                  v-if="menu.auth.includes(useraccess)"
                  :icon="menu.icon"
                  :label="menu.label"
                  :route="menu.route"
                />
              </div>
              <!-- Fim dos menus -->
            </ul>
          </div>
          <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
        <div class="sidebar-footer">
          <a href="javascript:void(0)">
            <i class="fa fa-bell"></i>
            <span v-if="notifys" class="badge badge-pill badge-warning notification">{{ notifys }}</span>
          </a>
          <a href="javascript:void(0)">
            <i class="fa fa-envelope"></i>
            <span v-if="msgs" class="badge badge-pill badge-success notification">{{ msgs }}</span>
          </a>
          <a href="javascript:void(0)" v-if="useraccess == 'Solicitante'">
            <i class="fas fa-clock"></i>
            <span v-if="varQueue" class="badge badge-pill badge-danger notification">{{ varQueue.queue }}</span>
          </a>
          <a v-if="online" href="" @click.prevent="logout">
            <i class="fa fa-power-off"></i>
          </a>
        </div>
      </nav>
      <!-- sidebar-wrapper  -->
      <main class="page-content">
        <div class="container-fluid">
          
          <div class="row ml-1">
            <img class="mt-2 mb-4" alt="Vue logo" src="./assets/img/logo.jpg" width="60">
            <div class="mt-3 ml-3">
              <span class="t-app mt-5" v-text="title"></span><br>
              <span class="mt-1 mb-4" v-text="localeName" style="color: #666;"></span>
            </div>
          </div>

          <!-- INSERÇÃO DOS COMPOENTES VIA ROTA -->
          <router-view :url="url" :token="token" @msgs="updateMsgs" @notifys="updateNotifys" @queue="updateQueue"></router-view>
          <!-- END -->
    
          <footer class="text-center py-5">
            <div class="mb-2">
                <small>
                    © {{ yearCurrent }} made with <i class="fa fa-heart" style="color:red"></i> by - <a target="_blank" rel="noopener noreferrer" href="https://www.ramcon.com.br">
                    Devalx Web Solutions
                    </a>
                </small>
                </div>

                <div>
                <a href="https://github.com/alexramos1977" target="_blank" class="btn btn-sm btn-secondary mr-1">
                    <i class="fab fa-github"></i>
                </a>
                <a href="https://linkedin.com/in/alexsandro-alves-ramos-11a861211" target="_blank" class="btn btn-sm btn-primary">
                    <i class="fab fa-linkedin"></i>
                </a>
            </div>
          </footer>
        </div>
      </main>
    </div>

  </div>
</template>

<script>

import OSCookie from 'js-cookie'
import axios from 'axios'

import MenuComponent from './components/snippets/MenuComponent.vue'
import Menus from './../src/menu.js'

export default {
  name: 'app',
  components:{
    MenuComponent,
  },
  data () {
    return {

      menus: Menus.show(),
      title: 'Ordem de Serviço',
      subtitle: OSCookie.get('_year'),
      online: false,
      username: OSCookie.get('_username'),
      userid: OSCookie.get('_userid'),
      useraccess: OSCookie.get('_useraccess'),
      yearCurrent: (new Date).getFullYear(),
      url: process.env.VUE_APP_URL,
      token: OSCookie.get('_os_token'),
      on: OSCookie.get('_online'),
      localeName: OSCookie.get('_locale_name'),
      notifys: 0,
      msgs: 0,
      varQueue: 0,
    }
  },
  created(){
    
    if(this.on)
    {
      this.notifications()
      this.messages()
      this.useraccess = this.useraccess[0].toUpperCase() + this.useraccess.substr(1)
      this.online = true

      let access = OSCookie.get('_useraccess')

      if(access == 'solicitante')
      {
        this.queue()
      }
    }

  },
  methods:{
    logout(){

      axios.post(this.url + '/logout', {}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(() => {

        OSCookie.remove('_email')
        OSCookie.remove('_password')
        OSCookie.remove('_os_token')
        OSCookie.remove('_online')
        OSCookie.remove('_locale_id')
        OSCookie.remove('_locale_name')
        OSCookie.remove('_username')
        OSCookie.remove('_userid')
        OSCookie.remove('_useraccess')
        OSCookie.remove('_year')

        this.$router.go(0)

      }, error => {

        console.log(error)
      })
    },
    notifications(){

      let userid = OSCookie.get('_userid')
      let access = OSCookie.get('_useraccess')
      let locale = OSCookie.get('_locale_id')
      let uri = (access == 'solicitante' ? 'responses' : 'requests')

      axios.post(this.url + '/' + uri + '/notifications', {locale: locale, user: userid}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

        this.notifys = response.data
      })
    },
    messages(){

      let userid = OSCookie.get('_userid')
      let access = OSCookie.get('_useraccess')
      let locale = OSCookie.get('_locale_id')
      let uri = (access == 'solicitante' ? 'responses' : 'requests')

      axios.post(this.url + '/' + uri + '/messages', {locale: locale, user: userid}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

        this.msgs = response.data
      })
    },
    queue(){

      let userid = OSCookie.get('_userid')
      let locale = OSCookie.get('_locale_id')

      axios.post(this.url + '/requests/queue', {locale: locale, user: userid}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

        this.varQueue = response.data

      })
    },
    updateMsgs(total){

      this.msgs = total
    },
    updateNotifys(total){

      this.notifys = total
    },
    updateQueue(total){

      this.varQueue = total
    },
  }
}
</script>

<style>

  .menu
  {
    color: #eee;
    text-decoration: none;
    padding: 10px 15px;
    background: #888;
    border-radius: 4px 4px 0 0;
    font-weight: bold;
  }
  .menu:hover
  {
    color: #fff;
    background: #999;
  }
  .logout
  {
    color: #FFEFD5;
    text-decoration: none;
    padding: 10px 15px;
    background: 	#f76b2b;
    border-radius: 4px 4px 0 0;
    font-weight: bold;
  }
  .logout:hover 
  {
    color: #fff;
    background: #f77b65;
  }
  .user 
  {
    color: #FFEFD5;
    text-decoration: none;
    padding: 10px 15px;
    background: 	#0d88c2;
    border-radius: 4px 4px 0 0;
    font-weight: bold;
  }
  .user:hover 
  {
    color: #fff;
    background: #1395d1;
  }
  .t-app 
  {
    font-size: 20pt;
    color: #666;
    font-weight: bold;
  }
  .bc
  {
    text-decoration: none;
    color: #999;
  }
  .separator
  {
    color: #999;
  }
  .bc-active 
  {
    color: #666;
    text-decoration: none;
  }
  .box-menu 
  {
    border-radius: 4px;
  }
</style>
