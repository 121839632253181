<template>
  <div>
    <div v-if="error" class="alert alert-danger"> 
      <div v-if="error.data.error" v-text="error.data.error"></div>

      <div v-if="error.data.errors">
        <ul>
          <li v-for="(error, index) in error.data.errors" :key="index">
            <span v-for="(item, index) in error" :key="index"> {{ item }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="mt-1">{{ title }}</h4>
      </div>

      <div class="card-body my-5">
          <form class="app_form" @submit.prevent="loginOS">

              <div class="col-12">
                  <div class="row">
                      <!-- {{-- LINHA 01 --}} -->
                      <div class="col-12 col-md-3"></div>
                      <div class="form-group col-12 col-md-6">
                          <span style="color: #999; font-weight: bold;">Email</span>
                          <input class="form-control" type="email" v-model="login.email">
                      </div>
                      <div class="col-12 col-md-3"></div>

                      <div class="col-12 col-md-3"></div>
                      <div class="form-group col-12 col-md-6">
                          <span style="color: #999; font-weight: bold;">Senha</span>
                          <input class="form-control" type="text" v-model="login.password">
                      </div>
                      <div class="col-12 col-md-3"></div>
                  </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-4"></div>
                <div class="col-12 col-md-4 text-center">
                    <button type="submit" class="btn btn-success align-right">Acessar Sistema</button>
                    <br><br>
                    <router-link class="login" to="/password/recovery">Recuperar Senha</router-link>
                    <br>
                    <router-link class="login" to="/user/create">Cadastre-se</router-link>
                </div>
                <div class="col-12 col-md-4"></div>
              </div>
          </form>
      </div> 
    </div>

    <loading-component v-if="preloader" />
  </div>
</template>

<script>

import LoadingComponent from '../snippets/LoadingComponent.vue'
import axios from 'axios'
import OSCookie from 'js-cookie'

export default {
  components: { LoadingComponent },
  props:[
    'url',
    'token'
  ],
  data() {
    return{

      title: 'Login',
      login:{
          // email: 'queziaalvarzsa@gmail.com',
          email: 'alexramos.pmi@gmail.com',
          password: '123',
      },
      error: false,
      preloader: false,

    }
  },
  created(){

    if(this.token)
    {
        this.$router.push('/home')
    }
  },
  methods:{
    loginOS(){

      this.preloader = true

      axios.post(this.url, this.login).then(response => {
          
        let token = response.data.auth.original.token_type + ' ' + response.data.auth.original.access_token
        //Dados do login
        OSCookie.set('_email', this.login.email)
        OSCookie.set('_password', this.login.password)
        //Atribui o valor do token ao cookie _os_token
        OSCookie.set('_os_token', token)
        //Ativa o cookie _online
        OSCookie.set('_online', true)
        //Atribui local de trabalho
        let localId = (response.data.location.call_center_id ? response.data.location.call_center_id : response.data.location.sub_sector_id)
        let localName = (response.data.location.call_center_name ? response.data.location.call_center_name : response.data.location.sub_sector_name)
        OSCookie.set('_locale_id', localId)
        OSCookie.set('_locale_name', localName)
        //Atribui o usuário ao cookie _user
        OSCookie.set('_username', response.data.user.name)
        OSCookie.set('_userid', response.data.user.id)
        OSCookie.set('_useraccess', response.data.user.access)
        //Ativa o cookie _year
        let data = new Date()
        OSCookie.set('_year', data.getFullYear())
        

        this.$router.go(0)

      }, error => {

        this.error = error.response

      }).finally(() => {

        this.preloader = false
      })
    }
  },
}
</script>

<style scoped>
  ul{
    list-style: none;
  }
  .login {
    font-size: 12pt;
  }
</style>