<template>
    <div>
        <message-component v-if="message" :message="message" />

        <!-- BREADCRUMB -->
        <div class="mt-4 mb-3">
            <router-link class="bc" to="/home">Home</router-link>
            <span class="separator"> > </span>
            <router-link class="bc" to="/response">Solicitações</router-link>
            <span class="separator"> > </span>
            <a href="javascript:void(0)" class="bc-active" v-text="this.id ? 'Editar' : 'Criar'"></a>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="mt-1">{{ title }}</h4>
            </div>

            <div class="card-body">
                <table>
                    <tbody>
                        <tr style="font-size: 10pt;">
                            <td style="width: 110px;">SOLICITANTE:</td>
                            <td><b>{{ request.user_name }}</b></td>
                        </tr>
                        <tr style="font-size: 10pt;">
                            <td>DESTINATÁRIO:</td>
                            <td><b>{{ request.call_center_name }}</b></td>
                        </tr>
                        <tr style="font-size: 10pt;">
                            <td>TÍTULO:</td>
                            <td><b>{{ request.title }}</b></td>
                        </tr>
                        <tr>
                            <td style="font-size: 10pt;">CRIADO:</td>
                            <td><b>{{ moment(request.created_at).format('DD/MM/yyyy HH:mm') }}</b></td>
                        </tr>
                        <tr>
                            <td class="pt-3" style="text-align: justify;" colspan="2" v-html="request.description"></td>
                        </tr>
                    </tbody>
                </table>

                <form class="app_form" @submit.prevent="saveResponse">

                    <!-- LINHA 1 -->
                    <span style="color: #999; font-weight: bold;">Descrição</span>
                    <vue2-tinymce-editor 
                        v-model="responseModel.description"
                        :options="tinymceOptions"
                    />
                    <small class="error" v-if="message.type == 'error'" v-text="message.message.description"></small>  

                    <button type="submit" class="btn btn-success mt-2" style="float:right;">Salvar Alterações</button>
                </form> 
            </div>
        </div>

        <loading-component v-if="preloader" />
    </div>
</template>

<script>

import LoadingComponent from '../snippets/LoadingComponent.vue'
import MessageComponent from './../snippets/MessageComponent.vue'
import {Vue2TinymceEditor} from 'vue2-tinymce-editor'
import axios from 'axios'
import moment from 'moment'
import OSCookie from 'js-cookie'

export default {
    components:{
        LoadingComponent,
        MessageComponent,
        Vue2TinymceEditor,
    },
    props: [
        'url',
        'token',
    ],
    data(){
        return{
            title: 'Solução',
            request: {},
            responseModel: {
                request_id: 0,
                user_id: OSCookie.get('_userid'),
                user_name: OSCookie.get('_username'),
                description: '',
            },
            message: {},
            preloader: false,
            idRequest: this.$route.params.request,
            moment: moment,
            tinymceOptions: {
                plugins: ['advlist autolink link image lists print preview hr searchreplace wordcount fullscreen insertdatetime media save table paste emoticons'],
            },
            id: 0,
        }
    },
    created(){

        this.getRequest()
        this.getResponse()
        this.countNotifys()
    },
    methods:{
        getRequest(){

            this.preloader = true

            axios.post(this.url + '/responses/requests', {id: this.idRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.request = response.data
                this.responseModel.request_id = response.data.id

            }, error => {

                console.log(error)

            }).finally(() => {

                this.preloader = false
            })
        },
        getResponse(){

            axios.post(this.url + '/responses', {id: this.idRequest}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                if(response.data)
                {
                    this.id = response.data.id
                    this.responseModel.description = ''
                }
            })
        },
        saveResponse(){

            if(this.id)
            {
                this.editResponse()
            }
            else 
            {
                this.createResponse()
            }
        },
        createResponse(){

            this.preloader = true

            axios.post(this.url + '/responses/store', this.responseModel, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.$router.push("/response")
                }
            }, error => {

                this.message = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        editResponse(){

            this.preloader = true

            axios.put(this.url + '/responses/update', {id: this.id, request_id: this.request.id, data: this.responseModel}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.message = response.data

                if(this.message.type != 'error' && this.message.type != 'danger' && this.message.type != 'warning')
                {
                    this.$router.push("/response")
                }
            }, error => {

                this.message = error.response

            }).finally(() => {

                this.preloader = false
            })
        },
        countNotifys(){

            let access = OSCookie.get('_useraccess')
            let locale = OSCookie.get('_locale_id')
            let uri = (access == 'solicitante' ? 'responses' : 'requests')

            axios.post(this.url + '/' + uri + '/notifications', {locale: locale}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                let count = response.data
                this.$emit('notifys', count)
            })
        },
    },
}
</script>

<style scoped>
    .error 
    {
        color: red;
    }
</style>